import { Button, Card, Form, Input, Layout } from "antd";
import { React, useState, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import { useNavigate } from "react-router-dom";
import getBrowserFingerprint from "get-browser-fingerprint";

const ChangePassword = (props) => {
  const [loading, setLoading] = useState(false);

  // load index context
  const { messageApi } = useContext(IndexContext);

  const [fetchWrapper] = useFetch();

  // for navigation
  const navigate = useNavigate();

  // browser fingerprint
  const fingerprint = getBrowserFingerprint();

  function changePassword(user_inputs) {
    fetchWrapper({
      endpoint_url: "user_management/changePassword",
      params: {
        old_password: user_inputs.old_password,
        new_password: user_inputs.new_password,
        fingerprint: fingerprint,
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.status === true) {
          // save password successfully
          messageApi.open({
            type: "success",
            content:
              "Changed password successfully. Redirecting to login page in 3s...",
          });

          setTimeout(() => {
            navigate("/Login", { replace: true });
          }, 3000);
        } else {
          // failed to save password
          if (fetch_output.hacked === true) {
            setTimeout(() => {
              navigate("/Login", { replace: true });
            }, 3000);
          } else {
            messageApi.open({
              type: "error",
              content: fetch_output.error,
            });
          }
          setLoading(false);
        }
      },
    });
  }

  return (
    <div style={{ height: "100%" }}>
      <Layout style={{ height: "100%" }}>
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            style={{ width: "95%", maxWidth: "500px", padding: "1rem 1.5rem" }}
            title={"Change Password"}
            loading={loading}
          >
            <Form
              name="changePassword"
              layout="vertical"
              onFinish={changePassword}
            >
              <Form.Item
                label="Old Password"
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Please input your old password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType={"submit"}
                  style={{ width: "100%" }}
                  type="primary"
                  size="large"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default ChangePassword;
