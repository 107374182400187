import { useContext, useEffect, useState } from "react";
import { Flex, Row, Col, Input } from "antd";
import { IndexContext } from "../../context/IndexContext";
import CameraFunc from "../../components/ai_rebar_count/camera/CameraFunc";
import QrScannerFunc from "../../components/ai_rebar_count/qrScanner/QrScannerFunc";
import useAddTagID from "../../functions/AIRebarCount/qr_scan/addTagID";
import useSnapRebarImage from "../../functions/AIRebarCount/snap_image/snapRebarImage";
import useCheckRebarStatus from "../../functions/AIRebarCount/snap_image/checkRebarStatus";
import useUploadRebarImage from "../../functions/AIRebarCount/manual_insert_form/uploadRebarImage";
import useAddRebarManually from "../../functions/AIRebarCount/manual_insert_form/addRebarManually";
import useRemoveImage from "../../functions/AIRebarCount/manual_insert_form/removeImage";
import ManualInsertModalForm from "../../components/ai_rebar_count/ManualInsertModalForm";
import useUpdateRebarReject from "../../functions/AIRebarCount/snap_image/updateRebarReject";
import DeliveryOrderModal from "../../components/ai_rebar_count/DeliveryOrderModal";
import useVerifyDeliveryOrder from "../../functions/AIRebarCount/qr_scan/verifyDeliveryOrder";
import useUpdateDeliveryOrderConnection from "../../functions/AIRebarCount/qr_scan/updateDeliveryOrderConnection";
import { useNavigate } from "react-router-dom";

export default function AIRebarCountPage() {
  // For handle the TagState or RebarState
  const [state, setState] = useState("tagState");
  // For handle the AI Process Error Count
  const [errorCount, setErrorCount] = useState(0);
  // For handle the Error Modal
  const [errorModal, setErrorModal] = useState(false);
  // For handle the Image Name that manual upload
  const [imageName, setImageName] = useState(null);
  // For handle the Image Src that manual upload
  const [imageSrc, setImageSrc] = useState(null);
  // For handle the Image Uploading (State, Progress, Result)
  const [imageUploading, setImageUploading] = useState({
    state: false,
    progress: 0,
    res: null,
  });
  // const [tagIdLoading, setTagIdLoading] = useState(false)
  const [navigateModal, setNavigateModal] = useState(false);
  const [resultType, setResultType] = useState(null);

  const navigate = useNavigate();
  const deliveryOrderID = localStorage.getItem("delivery_order_id");
  const { authenticationState, messageApi } = useContext(IndexContext);

  // Function to load progress after confirm the image
  function loadProgress() {
    const interval = setInterval(() => {
      setImageUploading((prevState) => {
        if (prevState.progress >= 90) {
          clearInterval(interval);
          return prevState;
        }
        return { ...prevState, progress: prevState.progress + 1, state: true };
      });
    }, 1000);
  }

  // For Reset the Image Upload
  const loadingReset = () => {
    setImageUploading({ state: false, progress: 0, res: null });
  };

  // Api Function for verify delivery order
  const { verifyDeliveryOrder, navigateResultType } = useVerifyDeliveryOrder();
  // Api Function for update user delivery order connection
  const { updateDeliveryOrderConnection, updateLoading } =
    useUpdateDeliveryOrderConnection();

  // Api Function for add Tag ID
  const { addTagID, tagId, setTagId, tagIdLoading } = useAddTagID();
  // Api Function for add Rebar with Captured Rebar Image
  const { snapRebarImage } = useSnapRebarImage();
  // Api Function for check Rebar Status
  const { checkRebarStatus, rebarKey, rebarCount, setRebarCount, aiImageSrc } =
    useCheckRebarStatus();
  // Api Function for retake Rebar Image
  const { updateRebarReject, retakeLoading, retakeSuccess } =
    useUpdateRebarReject();

  // Api Function for Manual Upload Rebar Image
  const { uploadRebarImage, rebarImageLoading } = useUploadRebarImage();
  // Api Fcuntion for add Rebar Data Manually
  const { addRebarManually, rebarFormLoading } = useAddRebarManually();
  // Api Function for remove Rebar Image that manual upload
  const { removeImage } = useRemoveImage();

  const resetErrorCount = () => setErrorCount(0);
  const addErrorCount = () => setErrorCount(errorCount + 1);

  // For reset Image data
  const resetImage = () => {
    setImageName(null);
    setImageSrc(null);
  };
  // For reset Error Count and Image
  const resetAll = () => {
    resetErrorCount();
    resetImage();
  };

  const handleVerifyDeliveryOrder = () => verifyDeliveryOrder(setNavigateModal);

  // For Submit the QR Scan Result
  const handleTagIDConfirm = (value, resetQrScanner) => {
    addTagID(value, setState, resetQrScanner);
  };

  // For Submit the Captured Rebar Image
  const handleRebarImageConfirm = (value) => {
    loadProgress();
    snapRebarImage(
      value,
      tagId,
      checkRebarStatus,
      setImageUploading,
      resetErrorCount,
      addErrorCount,
      setResultType
    );
  };

  // For Confirm the Rebar Result
  const onResultConfirm = () => {
    // setState("tagState");
    // setRebarCount(null);
    // setTagId(null);
    // handleVerifyDeliveryOrder();
    navigate("/RebarVerification", { state: { value: rebarKey } });
  };

  const onResultRetake = () => {
    updateRebarReject(rebarKey);
    handleVerifyDeliveryOrder();
  };

  // For handle Rebar Image Upload
  const handleRebarUpload = (value) => {
    const formData = new FormData();
    formData.append("upload_image", value.file);
    formData.append("tag_id", tagId.value);

    if (formData.has("upload_image") && formData.has("tag_id"))
      uploadRebarImage(formData, setImageName, setImageSrc);
  };

  // For handle Rebar Form Submit
  const handleRebarFormSubmit = (value) => {
    if (imageName == null) {
      messageApi.open({
        type: "warning",
        content: "Please upload Rebar Image",
      });
    } else {
      addRebarManually(value, tagId, imageName, setState, setTagId, resetAll);
    }
  };

  // For remove the Rebar Image that manual upload
  const handleImageRemove = () => {
    removeImage(imageName, resetImage);
  };

  const handleCloseConnection = () => updateDeliveryOrderConnection();

  // For checking the errorCount
  useEffect(() => {
    if (errorCount >= 3 && imageUploading.res == null) {
      setErrorModal(true);
    } else {
      setErrorModal(false);
    }
  }, [errorCount, imageUploading]);

  useEffect(() => {
    if (retakeSuccess) setRebarCount(null);
  }, [retakeLoading, retakeSuccess]);

  useEffect(() => {
    window.scrollTo(0, 500);
    handleVerifyDeliveryOrder();
  }, []);

  return (
    <div>
      <Flex justify="center">
        <div className="responsive-row">
          <Flex vertical="horizontal">
            <Row gutter={[16, 16]} justify="end">
              <Col className="responsive-col">
                <Input
                  addonBefore="Login ID:"
                  value={authenticationState.user_name}
                  readOnly
                />
              </Col>
              <Col className="responsive-col">
                <Input
                  addonBefore={deliveryOrderID != null ? "DO: " : "Stage:"}
                  value={
                    deliveryOrderID != null
                      ? deliveryOrderID
                      : authenticationState.user_stage
                  }
                  readOnly
                />
              </Col>
            </Row>
            {state === "tagState" ? (
              <QrScannerFunc
                isLoading={tagIdLoading}
                handleConfirm={handleTagIDConfirm}
              />
            ) : (
              <>
                <Row>
                  <Col className="responsive-col">
                    <Input
                      addonBefore="Serial Number:"
                      value={tagId.value}
                      readOnly
                    />
                  </Col>
                </Row>
                <CameraFunc
                  content={
                    <span>
                      Click the camera to snap
                      <br />
                      Rebar Bundle Image
                    </span>
                  }
                  shape={"circle"}
                  cameraSide={"back"}
                  setFinalImage={handleRebarImageConfirm}
                  isLoading={imageUploading}
                  resultType={resultType}
                  rebarCount={rebarCount}
                  aiImageSrc={aiImageSrc}
                  onResultConfirm={onResultConfirm}
                  onResultRetake={onResultRetake}
                  retakeSuccess={retakeSuccess}
                  retakeLoading={retakeLoading}
                  loadingReset={loadingReset}
                />
              </>
            )}
          </Flex>
        </div>
      </Flex>
      <ManualInsertModalForm
        modalOpen={errorModal}
        setModalOpen={setErrorModal}
        uploadLoading={rebarImageLoading}
        handleUpload={handleRebarUpload}
        handleSubmit={handleRebarFormSubmit}
        formLoading={rebarFormLoading}
        imageSrc={imageSrc}
        removeImage={handleImageRemove}
      />
      <DeliveryOrderModal
        modalOpen={navigateModal}
        setModalOpen={setNavigateModal}
        resultType={navigateResultType}
        handleCloseConnection={handleCloseConnection}
        updateLoading={updateLoading}
      />
    </div>
  );
}
