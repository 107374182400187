import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Flex } from "antd";
import { useEffect, useState } from "react";
import MechanicalTable from "../../components/material_properties/mechanical_properties/MechanicalTable";
import ChemicalTable from "../../components/material_properties/chemical_composition/ChemicalTable";
import MechanicalModalForm from "../../components/material_properties/mechanical_properties/MechanicalModalForm";
import ChemicalModalForm from "../../components/material_properties/chemical_composition/ChemicalModalForm";
import MechanicalSearchForm from "../../components/material_properties/mechanical_properties/MechanicalSearchForm";
import ChemicalSearchForm from "../../components/material_properties/chemical_composition/ChemicalSearchForm";
import useAddMechanicalProperties from "../../functions/MaterialProperties/mechanical_properties/addMechanicalProperties";
import useAddChemicalComposition from "../../functions/MaterialProperties/chemical_composition/addChemicalComposition";
import useUpdateMechanicalProperties from "../../functions/MaterialProperties/mechanical_properties/updateMechanicalProperties";
import useUpdateChemicalComposition from "../../functions/MaterialProperties/chemical_composition/updateChemicalComposition";
import useGetAllChemicalComposition from "../../functions/MaterialProperties/chemical_composition/getAllChemicalComposition";
import useGetAllMechanicalProperties from "../../functions/MaterialProperties/mechanical_properties/getAllMechanicalProperties";
import useRemoveMechenicalProperties from "../../functions/MaterialProperties/mechanical_properties/removeMechanicalProperties";
import useRemoveChemicalComposition from "../../functions/MaterialProperties/chemical_composition/removeChemicalComposition";
import useDownloadMechanicalProperties from "../../functions/MaterialProperties/mechanical_properties/downloadMechanicalProperties";
import useDownloadChemicalComposition from "../../functions/MaterialProperties/chemical_composition/downloadChemicalComposition";
import dayjs from "dayjs";
import CustomRangePicker from "../../components/common/CustomRangePicker";

const materialPropertiesTab = [
  {
    label: "Mechanical Properties",
    key: "mechanical_properties",
  },
  {
    label: "Chemical Composition",
    key: "chemical_composition",
  },
];

export default function MaterialPropertiesPage() {
  // For handle active tab
  const [activeTab, setActiveTab] = useState("mechanical_properties");
  // For change tab
  const onTabChange = (value) => setActiveTab(value);

  // For handle data start date
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day").startOf("day")
  );
  // For handle data end date
  const [endDateSelection, setEndDateSelection] = useState(
    dayjs().endOf("day")
  );

  // For handle download file loading
  const [downloadLoading, setDownloadLoading] = useState(false);
  // For handle form loading
  const [formLoading, setFormLoading] = useState(false);

  // For handle each tab data have been filter
  const [filterMechanical, setFilterMechanical] = useState([]);
  const [filterChemical, setFilterChemical] = useState([]);

  // For handle each tab original data
  const [mechanicalModal, setMechanicalModal] = useState(false);
  const [chemicalModal, setChemicalModal] = useState(false);

  // For handle each tab search modal
  const [mechanicalSearch, setMechanicalSearch] = useState(false);
  const [chemicalSearch, setChemicalSearch] = useState(false);

  // For handle each tab edit data
  const [editMechanincal, setEditMechanical] = useState(null);
  const [editChemical, setEditChemical] = useState(null);

  // For each tab api call get all data
  const {
    getAllMechanicalProperties,
    mechanicalProperties,
    mechanicalLoading,
  } = useGetAllMechanicalProperties();
  const { getAllChemicalComposition, chemicalComposition, chemicalLoading } =
    useGetAllChemicalComposition();

  // For each tab api call add data
  const { addMechanicalProperties } = useAddMechanicalProperties();
  const { addChemicalComposition } = useAddChemicalComposition();

  // For each tab api call update data
  const { updateMechanicalProperties } = useUpdateMechanicalProperties();
  const { updateChemicalComposition } = useUpdateChemicalComposition();

  // For each tab api call delete data
  const { removeMechanicalProperties, mechanicalDeleteLoading } =
    useRemoveMechenicalProperties();
  const { removeChemicalComposition, chemicalDeleteLoading } =
    useRemoveChemicalComposition();

  // For each tab api call download data
  const { downloadMechanicalProperties } = useDownloadMechanicalProperties();
  const { downloadChemicalComposition } = useDownloadChemicalComposition();

  const handleMechanicalProperties = () =>
    getAllMechanicalProperties(startDateSelection, endDateSelection);
  const handleChemicalComposition = () =>
    getAllChemicalComposition(startDateSelection, endDateSelection);

  // Handlers for each tab: MechanicalProperties, ChemicalComposition
  const handlers = {
    mechanical_properties: {
      addOpen: () => setMechanicalModal(true),
      editOpen: (value) => {
        setEditMechanical(value);
        setMechanicalModal(true);
      },
      searchOpen: () => setMechanicalSearch(true),
      download: () =>
        downloadMechanicalProperties(mechanicalProperties, setDownloadLoading),
      reset: () => setFilterMechanical(mechanicalProperties),
      filterDateSubmit: () =>
        getAllMechanicalProperties(startDateSelection, endDateSelection),
      addSubmit: (value) =>
        addMechanicalProperties(
          value,
          handleMechanicalProperties,
          setFormLoading,
          setMechanicalModal
        ),
      editSubmit: (value, key) =>
        updateMechanicalProperties(
          value,
          key,
          handleMechanicalProperties,
          setFormLoading,
          setMechanicalModal
        ),
      deleteSubmit: (key) =>
        removeMechanicalProperties(key, handleMechanicalProperties),
    },
    chemical_composition: {
      addOpen: () => setChemicalModal(true),
      editOpen: (value) => {
        setEditChemical(value);
        setChemicalModal(true);
      },
      searchOpen: () => setChemicalSearch(true),
      download: () =>
        downloadChemicalComposition(chemicalComposition, setDownloadLoading),
      reset: () => setFilterChemical(chemicalComposition),
      filterDateSubmit: () =>
        getAllChemicalComposition(startDateSelection, endDateSelection),
      addSubmit: (value) =>
        addChemicalComposition(
          value,
          handleChemicalComposition,
          setFormLoading,
          setChemicalModal
        ),
      editSubmit: (value, key) =>
        updateChemicalComposition(
          value,
          key,
          handleChemicalComposition,
          setFormLoading,
          setChemicalModal
        ),
      deleteSubmit: (key) =>
        removeChemicalComposition(key, handleChemicalComposition),
    },
  };

  const handleDateFilter = () => {
    const handler = handlers[activeTab];
    if (handler && handler.searchOpen) handler.filterDateSubmit();
  };

  // Function to handle the AddOpen action based on active tab
  const handleAddOpen = () => {
    const handler = handlers[activeTab];
    if (handler && handler.addOpen) handler.addOpen();
  };

  // Function to handle the EditOpen action based on active tab
  const handleEditOpen = (value) => {
    const handler = handlers[activeTab];
    if (handler && handler.editOpen) handler.editOpen(value);
  };

  // Function to handle the Download action based on active tab
  const handleDownload = () => {
    const handler = handlers[activeTab];
    if (handler && handler.download) handler.download();
  };

  // Function to handle the Reset action based on active tab
  const handleReset = () => {
    const handler = handlers[activeTab];
    if (handler && handler.reset) handler.reset();
  };

  // Function to handle the SearchOpen action based on active tab
  const handleSearchOpen = () => {
    const handler = handlers[activeTab];
    if (handler && handler.searchOpen) handler.searchOpen();
  };

  // Function to handle the AddSubmit action and EditSubmit action based on active tab and key
  const handleFormSubmit = (value, key) => {
    const handler = handlers[activeTab];
    if (key == null) {
      if (handler && handler.addSubmit) handler.addSubmit(value);
    } else {
      if (handler && handler.editSubmit) handler.editSubmit(value, key);
    }
  };

  // Function to handle the DeleteSubmit action based on active tab
  const handleDeleteSubmit = (key) => {
    const handler = handlers[activeTab];
    if (handler && handler.deleteSubmit) handler.deleteSubmit(key);
  };

  const materialContent = {
    mechanical_properties: (
      <MechanicalTable
        tableData={filterMechanical}
        handleEdit={handleEditOpen}
        handleDelete={handleDeleteSubmit}
        deleteLoading={mechanicalDeleteLoading}
      />
    ),
    chemical_composition: (
      <ChemicalTable
        tableData={filterChemical}
        handleEdit={handleEditOpen}
        handleDelete={handleDeleteSubmit}
        deleteLoading={chemicalDeleteLoading}
      />
    ),
  };

  useEffect(() => {
    setFilterMechanical(mechanicalProperties);
  }, [mechanicalProperties]);

  useEffect(() => {
    setFilterChemical(chemicalComposition);
  }, [chemicalComposition]);

  useEffect(() => {
    getAllMechanicalProperties(startDateSelection, endDateSelection);
    getAllChemicalComposition(startDateSelection, endDateSelection);
  }, []);

  return (
    <>
      <Card
        className="context-card"
        tabList={materialPropertiesTab}
        activeTabKey={activeTab}
        onTabChange={onTabChange}
        loading={mechanicalLoading || chemicalLoading}
        tabBarExtraContent={
          <Flex gap="small" align="center" wrap>
            <CustomRangePicker
              startDateSelection={startDateSelection}
              setStartDateSelection={setStartDateSelection}
              endDateSelection={endDateSelection}
              setEndDateSelection={setEndDateSelection}
              disabled={mechanicalLoading || chemicalLoading}
              search={true}
              showTime={false}
              onFilterClick={handleDateFilter}
            />
            <Button
              icon={<DownloadOutlined />}
              onClick={handleDownload}
              disabled={mechanicalLoading || chemicalLoading}
              loading={downloadLoading}
            />
            <Button
              children="Reset"
              onClick={handleReset}
              disabled={mechanicalLoading || chemicalLoading}
            />
            <Button
              type="primary"
              children="Advanced Search"
              disabled={mechanicalLoading || chemicalLoading}
              onClick={handleSearchOpen}
            />
            <Button
              icon={<PlusOutlined style={{ color: "white" }} />}
              onClick={handleAddOpen}
              disabled={mechanicalLoading || chemicalLoading}
              style={{ backgroundColor: "#0000A3" }}
            />
          </Flex>
        }
      >
        {materialContent[activeTab]}
      </Card>

      <MechanicalModalForm
        modalOpen={mechanicalModal}
        setModalOpen={setMechanicalModal}
        editData={editMechanincal}
        setEditData={setEditMechanical}
        onSubmit={handleFormSubmit}
        loading={formLoading}
      />
      <ChemicalModalForm
        modalOpen={chemicalModal}
        setModalOpen={setChemicalModal}
        editData={editChemical}
        setEditData={setEditChemical}
        onSubmit={handleFormSubmit}
        loading={formLoading}
      />

      <MechanicalSearchForm
        modalOpen={mechanicalSearch}
        setModalOpen={setMechanicalSearch}
        tableData={mechanicalProperties}
        setFilterTable={setFilterMechanical}
      />
      <ChemicalSearchForm
        modalOpen={chemicalSearch}
        setModalOpen={setChemicalSearch}
        tableData={chemicalComposition}
        setFilterTable={setFilterChemical}
      />
    </>
  );
}
