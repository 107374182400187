import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";
import { useNavigate } from "react-router-dom";

function useUpdateDeliveryOrderConnection() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [updateLoading, setUpdateLoading] = useState(false);

  const updateDeliveryOrderConnection = useCallback(() => {
    return new Promise((resolve, reject) => {
      setUpdateLoading(true);
      fetchWrapper({
        endpoint_url: "ai_rebar_count/updateDeliveryOrderConnection",
        setLoading: setUpdateLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            navigate("/DeliveryOrderConnection", { replace: true });
          } else {
            messageApi.open({
              type: "warning",
              content: fetch_output.error,
            });
          }
          resolve(fetch_output);
        },
        onFailure: (error) => {
          messageApi.open({
            type: "error",
            content: error,
          });
          reject(error);
        },
        onFetchError: () => {
          messageApi.open({
            type: "error",
            content:
              "Network Error. Failed to update delivery order connection",
          });
        },
        onResponseError: () => {
          messageApi.open({
            type: "error",
            content:
              "Failed to Respond (Update Delivery Order Conncetion), Please Contact Admin",
          });
        },
      });
    });
  }, [fetchWrapper]);
  return { updateDeliveryOrderConnection, updateLoading };
}

export default useUpdateDeliveryOrderConnection;
