import { useEffect, useRef } from "react";

export default function RebarVerificationEditImage({
  imageSrc,
  addCount,
  minusCount,
  shapeSize,
  setCoordinate,
  circles,
  crosses,
  setCircles,
  setCrosses,
}) {
  const canvasRef = useRef(null); // Reference to the canvas element
  const longClickDuration = 500; // Duration for long click detection
  const timerId = useRef(null); // Reference to store timer ID for long click
  const isLongClick = useRef(false); // Flag to track long click state
  const isTouchEvent = useRef(false); // Flag to track if a touch event is occurring

  // Function to draw the canvas image and existing shapes (circles and crosses)
  const drawCanvasImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const width = canvas.clientWidth; // Get the width of the canvas
    const height = canvas.clientHeight; // Get the height of the canvas

    // Set canvas dimensions
    canvas.width = width;
    canvas.height = height;

    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      // Draw the image into the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Draw circles on the canvas
      circles.forEach((circle) => {
        const pixelX = circle.x * canvas.width;
        const pixelY = circle.y * canvas.height;

        ctx.beginPath();
        ctx.arc(pixelX, pixelY, circle.shapeSize, 0, Math.PI * 2);
        ctx.strokeStyle = "green";
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();
      });

      // Draw crosses on the canvas
      crosses.forEach((cross) => {
        const { x, y, shapeSize } = cross;
        ctx.strokeStyle = "green";
        ctx.lineWidth = 2;

        const pixelX = x * canvas.width;
        const pixelY = y * canvas.height;

        // Diagonal Line 1 (Top-left to Bottom-right)
        ctx.beginPath();
        ctx.moveTo(pixelX - shapeSize / 2, pixelY - shapeSize / 2);
        ctx.lineTo(pixelX + shapeSize / 2, pixelY + shapeSize / 2);
        ctx.stroke();

        // Diagonal Line 2 (Bottom-left to Top-right)
        ctx.beginPath();
        ctx.moveTo(pixelX - shapeSize / 2, pixelY + shapeSize / 2);
        ctx.lineTo(pixelX + shapeSize / 2, pixelY - shapeSize / 2);
        ctx.stroke();
      });
    };
  };

  // Calculate distance between two points
  const getDistance = (x1, y1, x2, y2) => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy);
  };

  // Handle click on circle
  const handleCircleClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    // Normalize the click coordinates
    const x = (event.clientX - rect.left) / canvas.width;
    const y = (event.clientY - rect.top) / canvas.height;

    // Find the circle where the click occurred within its radius (shapeSize/2)
    const existingCircle = circles.findIndex((circle) => {
      const distance = getDistance(circle.x, circle.y, x, y);
      // Calculate distance based on normalized x, y, and shape size
      return distance <= circle.shapeSize / canvas.width;
    });

    if (existingCircle !== -1) {
      const newCircles = [...circles];
      newCircles.splice(existingCircle, 1);
      setCircles(newCircles);
      minusCount();
    } else {
      const newCircle = { x, y, shapeSize, key: null };
      setCircles([...circles, newCircle]);
      addCount();
    }
  };

  // Handle click on cross
  const handleCrossClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    // Normalize the click coordinates
    const x = (event.clientX - rect.left) / canvas.width;
    const y = (event.clientY - rect.top) / canvas.height;

    // Find the cross where the click occurred within its radius (shapeSize/2)
    const existingCross = crosses.findIndex((cross) => {
      const distance = getDistance(cross.x, cross.y, x, y);
      // Calculate distance based on normalized x, y, and shape size
      return distance <= cross.shapeSize / canvas.width;
    });

    if (existingCross !== -1) {
      const newCrosses = [...crosses];
      newCrosses.splice(existingCross, 1);
      setCrosses(newCrosses);
      addCount();
    } else {
      const newCross = { x, y, shapeSize, key: null };
      setCrosses([...crosses, newCross]);
      minusCount();
    }
  };

  // Handle mouse leaving the canvas
  const handleMouseLeave = () => {
    clearTimeout(timerId.current);
  };

  // Handle mouse down event
  const handleMouseDown = (event) => {
    if (isTouchEvent.current) return;
    isLongClick.current = false;
    startLongClickTimer(event);
  };

  // Handle mouse up event
  const handleMouseUp = (event) => {
    if (isTouchEvent.current) {
      isTouchEvent.current = false;
      return;
    }
    clearTimeout(timerId.current);
    if (!isLongClick.current) {
      handleCircleClick(event);
    }
  };

  // Handle touch start event
  const handleTouchStart = (event) => {
    isTouchEvent.current = true;
    isLongClick.current = false;
    startLongClickTimer(event.touches[0]);
    event.preventDefault();
  };

  // Handle touch end event
  const handleTouchEnd = (event) => {
    clearTimeout(timerId.current);
    if (!isLongClick.current) {
      handleCircleClick(event.changedTouches[0]);
    }
    event.preventDefault();
  };

  // Start the long click timer
  const startLongClickTimer = (event) => {
    isLongClick.current = false;
    timerId.current = setTimeout(() => {
      isLongClick.current = true;
      handleCrossClick(event);
    }, longClickDuration);
  };

  // Effect to draw the image and update coordinates on changes
  useEffect(() => {
    drawCanvasImage();
    setCoordinate({
      circle: circles,
      cross: crosses,
    });
  }, [circles, crosses, imageSrc]);

  return (
    <canvas
      ref={canvasRef}
      className="canvas-image"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    />
  );
}
