import { Button, Card, Flex, Select, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import StockPage from "../../components/statistics/stock/StockPage";
import OperatorPage from "../../components/statistics/operator/OperatorPage";
import ShiftPage from "../../components/statistics/shift/ShiftPage";
import StatisticWIPPage from "../../components/statistics/statistic-wip/StatisticWIPPage";
import ProductionPage from "../../components/statistics/production/ProductionPage";
import DeliveryPage from "../../components/statistics/delivery/DeliveryPage";
import useGetFactoryOption from "../../functions/Options/getFactoryOption";
import useGetDiameterTypeOption from "../../functions/Options/getDiameterTypeOption";
import useGetOperatorOption from "../../functions/Options/getOperatorOption";
import dayjs from "dayjs";
import CustomRangePicker from "../../components/common/CustomRangePicker";
import {
  CiOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import useGetStockData from "../../functions/Statistics/Stock/getStockData";
import useGetDeliveryData from "../../functions/Statistics/Delivery/getDeliveryData";
import useGetStatisticWipData from "../../functions/Statistics/Statistic-WIP/getStatisticWipData";
import useGetOperatorData from "../../functions/Statistics/Operator/getOperatorData";
import useGetShiftData from "../../functions/Statistics/Shift/getShiftData";
import useDownloadShift from "../../functions/Statistics/Shift/downloadShift";
import useDownloadOperator from "../../functions/Statistics/Operator/downloadOperator";
import useDownloadStatisticWip from "../../functions/Statistics/Statistic-WIP/downloadStatisticWip";
import useDownloadDelivery from "../../functions/Statistics/Delivery/downloadDelivery";
import useDownloadStock from "../../functions/Statistics/Stock/downloadStock";
import useGetProductionData from "../../functions/Statistics/Production/getProductionData";
import useDownloadProduction from "../../functions/Statistics/Production/downloadProduction";

const TIME_OPTION = [
  {
    label: "Day",
    value: "date",
  },
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Year",
    value: "year",
  },
];

const statisticTab = [
  {
    label: "Stock",
    key: "stock",
  },
  {
    label: "Shift",
    key: "shift",
  },
  {
    label: "Operator",
    key: "operator",
  },
  {
    label: "WIP",
    key: "statistic_wip",
  },
  {
    label: "Production",
    key: "production",
  },
  {
    label: "Delivery Output",
    key: "delivery",
  },
];

const ACTIVE_OPERATOR_SELECT = ["operator"];

export default function StatisticsPage() {
  // For handle the active tab of card
  const [activeTab, setActiveTab] = useState("stock");
  // For handle the data loading state
  const [isLoading, setIsLoading] = useState(true);
  // For handle downloading file loading state
  const [downloadLoading, setDownloadLoading] = useState(false);
  // For handle changing of the active tab
  const onTabChange = (value) => setActiveTab(value);
  const firstRender = useRef(true);

  // For handle data start date
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day").startOf("day")
  );
  // For handle data end date
  const [endDateSelection, setEndDateSelection] = useState(
    dayjs().endOf("day")
  );

  // For handle select time option
  const [timeSelect, setTimeSelect] = useState(TIME_OPTION[0].value);
  // For get and handle factory option
  const { factoryOption, factorySelect, setFactorySelect } =
    useGetFactoryOption();
  // For get and handle diameter option
  const { diameterOption, diameterSelect, setDiameterSelect } =
    useGetDiameterTypeOption();
  // For get and handle operator option
  const { operatorOption, operatorSelect, setOperatorSelect } =
    useGetOperatorOption();

  // For handle Factory Select Value
  const handleFactorySelect = (value) => setFactorySelect(value);
  // For handle Diameter Select Value
  const handleDiameterSelect = (value) => setDiameterSelect(value);
  // For handle Time Select Value
  const handleTimeSelect = (value) => setTimeSelect(value);
  // For handle Operator Select Value
  const handleOperatorSelect = (value) => setOperatorSelect(value);

  // For get and handle stock table data, chart data and column
  const {
    getStockData,
    stockTableData,
    stockColumn,
    stockChartData,
    stockDownload,
  } = useGetStockData();
  // For download Stock data into Excel file
  const { downloadStock } = useDownloadStock();

  // For get and handle shift table data, chart data and column
  const {
    getShiftData,
    shiftTableData,
    shiftColumn,
    shiftChartData,
    shiftDownload,
  } = useGetShiftData();
  // For download Shift data into Excel file
  const { downloadShift } = useDownloadShift();

  // For get and handle operator table data, chart data and column
  const {
    getOperatorData,
    operatorTableData,
    operatorColumn,
    operatorChartData,
    operatorDownload,
  } = useGetOperatorData();
  // For download Operator data into Excel file
  const { downloadOperator } = useDownloadOperator();

  // For get and handle statistic-wip table data, chart data and column
  const {
    getStatisticWipData,
    statisticWipTableData,
    statisticWipColumn,
    statisticWipChartData,
    statisticWipDownload,
  } = useGetStatisticWipData();
  // For download Statistic-WIP data into Excel file
  const { downloadStatisticWip } = useDownloadStatisticWip();

  // For get and handle delivery table data, chart data and column
  const {
    getDeliveryData,
    deliveryTableData,
    deliveryColumn,
    deliveryChartData,
    deliveryDownload,
  } = useGetDeliveryData();
  // For download Delivery data into Excel file
  const { downloadDelivery } = useDownloadDelivery();

  const {
    getProductionData,
    productionTableData,
    productionColumn,
    productionChartData,
    productionDownload,
  } = useGetProductionData();
  const { downloadProduction } = useDownloadProduction();

  // Function to handle Inventory Data retrieval
  const handleStockData = () => {
    getStockData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  // Function to handle Shift Data retrieval
  const handleShiftData = () => {
    getShiftData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  // Function to handle Operator Data retrieval
  const handleOperatorData = () => {
    getOperatorData(
      factorySelect,
      diameterSelect,
      operatorSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  // Function to handle Statistic-WIP Data retrieval
  const handleStatisticWipData = () => {
    getStatisticWipData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  // Function to handle Delivery Data retrieval
  const handleDeliveryData = () => {
    getDeliveryData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  const handleProductionData = () => {
    getProductionData(
      factorySelect,
      diameterSelect,
      startDateSelection,
      endDateSelection,
      timeSelect,
      setIsLoading
    );
  };

  // Handlers for each tab: Inventory, Shift, Operator, Statistic WIP, Delivery
  const handlers = {
    stock: {
      apply: handleStockData,
      download: () => downloadStock(stockDownload, setDownloadLoading),
    },
    shift: {
      apply: handleShiftData,
      download: () => downloadShift(shiftDownload, setDownloadLoading),
    },
    operator: {
      apply: handleOperatorData,
      download: () => downloadOperator(operatorDownload, setDownloadLoading),
    },
    statistic_wip: {
      apply: handleStatisticWipData,
      download: () =>
        downloadStatisticWip(statisticWipDownload, setDownloadLoading),
    },
    production: {
      apply: handleProductionData,
      download: () =>
        downloadProduction(productionDownload, setDownloadLoading),
    },
    delivery: {
      apply: handleDeliveryData,
      download: () => downloadDelivery(deliveryDownload, setDownloadLoading),
    },
  };

  // Function to handle the Apply action based on active tab
  const handleApply = () => {
    const handler = handlers[activeTab];
    if (handler && handler.apply) {
      handler.apply();
    }
  };

  // Function to handle the Download action based on active tab
  const handleDownload = () => {
    const handler = handlers[activeTab];
    if (handler && handler.download) {
      handler.download();
    }
  };

  const statisticContent = {
    stock: (
      <StockPage
        chartData={stockChartData}
        tableData={stockTableData}
        tableColumn={stockColumn}
      />
    ),
    shift: (
      <ShiftPage
        chartData={shiftChartData}
        tableData={shiftTableData}
        tableColumn={shiftColumn}
      />
    ),
    operator: (
      <OperatorPage
        chartData={operatorChartData}
        tableData={operatorTableData}
        tableColumn={operatorColumn}
      />
    ),
    statistic_wip: (
      <StatisticWIPPage
        chartData={statisticWipChartData}
        tableData={statisticWipTableData}
        tableColumn={statisticWipColumn}
      />
    ),
    production: (
      <ProductionPage
        chartData={productionChartData}
        tableData={productionTableData}
        tableColumn={productionColumn}
      />
    ),
    delivery: (
      <DeliveryPage
        chartData={deliveryChartData}
        tableData={deliveryTableData}
        tableColumn={deliveryColumn}
      />
    ),
  };

  // For only call api once the select changes
  useEffect(() => {
    if (
      factorySelect != null &&
      diameterSelect != null &&
      operatorOption != null
    ) {
      if (firstRender.current) {
        firstRender.current = false;
        handleStockData();
        handleShiftData();
        handleOperatorData();
        handleDeliveryData();
        handleStatisticWipData();
        handleProductionData();
      }
    }
  }, [factorySelect, diameterSelect, operatorSelect]);

  useEffect(() => {
    if (timeSelect === "week") {
      setStartDateSelection(dayjs().subtract(1, "week").startOf("week"));
      setEndDateSelection(dayjs().endOf("week"));
    } else if (timeSelect === "month") {
      setStartDateSelection(dayjs().subtract(1, "month").startOf("month"));
      setEndDateSelection(dayjs().endOf("month"));
    } else if (timeSelect === "year") {
      setStartDateSelection(dayjs().subtract(1, "year").startOf("year"));
      setEndDateSelection(dayjs().endOf("year"));
    } else {
      setStartDateSelection(dayjs().subtract(6, "day").startOf("day"));
      setEndDateSelection(dayjs().endOf("day"));
    }
  }, [timeSelect]);

  return (
    <Card
      className="context-card"
      tabList={statisticTab}
      activeTabKey={activeTab}
      onTabChange={onTabChange}
      loading={isLoading}
    >
      <Flex gap="small" wrap align="center" justify="space-between">
        <Flex gap="small" wrap align="center">
          <Typography.Text children={"Factory:"} />
          <Select
            mode="multiple"
            style={{ width: "200px" }}
            options={factoryOption}
            value={factorySelect}
            onChange={handleFactorySelect}
            placeholder="Factory Select"
            disabled={isLoading}
          />
        </Flex>
        <Flex gap="small" wrap align="center">
          {ACTIVE_OPERATOR_SELECT.includes(activeTab) && (
            <Flex gap="small" wrap align="center">
              <Typography.Text children={"Operator:"} />
              <Select
                mode="multiple"
                style={{ width: "300px" }}
                options={operatorOption}
                value={operatorSelect}
                onChange={handleOperatorSelect}
                placeholder="Operator Select"
                maxCount={5}
                disabled={isLoading}
              />
            </Flex>
          )}

          <Flex gap="small" wrap align="center">
            <Typography.Text children={"Product:"} />
            <Select
              mode="multiple"
              style={{ width: "300px" }}
              options={diameterOption}
              value={diameterSelect}
              onChange={handleDiameterSelect}
              placeholder="Diameter Type Select"
              maxCount={5}
              disabled={isLoading}
            />
          </Flex>
          <CustomRangePicker
            startDateSelection={startDateSelection}
            setStartDateSelection={setStartDateSelection}
            endDateSelection={endDateSelection}
            setEndDateSelection={setEndDateSelection}
            timePicker={timeSelect}
            disabled={isLoading}
            showTime={true}
          />
          <Select
            style={{ width: "100px" }}
            options={TIME_OPTION}
            value={timeSelect}
            onChange={handleTimeSelect}
            placeholder="Month"
            disabled={isLoading}
          />
          <Button
            type="primary"
            disabled={isLoading}
            children="Apply"
            onClick={handleApply}
          />
          <Button
            icon={downloadLoading ? <LoadingOutlined /> : <DownloadOutlined />}
            disabled={isLoading || downloadLoading}
            onClick={handleDownload}
          />
        </Flex>
      </Flex>
      {statisticContent[activeTab]}
    </Card>
  );
}
