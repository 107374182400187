// library imports
import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider, message, notification } from "antd";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// style imports
import "./styles/index.css";
// config imports
import {
  route_basename,
  operator,
  supervisor,
  manager,
  account,
  qcOperator,
} from "./configs/configs";
// context imports
import { IndexContext } from "./context/IndexContext";
// hook imports
import useThemeSwitcher from "./hooks/useThemeSwitcher";
// component/page imports
import MainLayout from "./layout/MainLayout";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/login/LoginPage";
import LoginRecordPage from "./pages/login_record/LoginRecordPage";
import BundleListPage from "./pages/bundle_list/BundleListPage";
import RebarVerificationPage from "./pages/rebar_verification/RebarVerificationPage";
import CameraTest from "./pages/test/CameraTest";
import QrScannerTest from "./pages/test/QrScannerTest";
import TableDataTest from "./pages/test/TableDataTest";
import ProtectedRoute from "./routes/ProtectedRoutes";
import AIRebarCountPage from "./pages/ai_rebar_count/AIRebarCountPage";
import TraceabilityPage from "./pages/traceability/TraceabilityPage";
import StatisticsPage from "./pages/statistics/StatisticsPage";
import DownloadTest from "./pages/test/DownloadTest";
import ChangePassword from "./pages/account/ChangePassword";
import MaterialPropertiesPage from "./pages/material_properties/MaterialPropertiesPage";
import DeliveryOrderConnectionPage from "./pages/delivery_order_connection/DeliveryOrderConnectionPage";
import DeliveryOrderPage from "./pages/delivery_order/DeliveryOrderPage";
import DOProgressPage from "./pages/do_progress/DOProgressPage";
import ConfigurationPage from "./pages/configuration/ConfigurationPage";

const App = () => {
  // load theme switcher
  const [appTheme, updateTheme, isLightTheme] = useThemeSwitcher();

  // for triggering message
  const [messageApi, messageApiContext] = message.useMessage();
  // for triggering notification
  const [notificationApi, notificationApiContext] =
    notification.useNotification();

  return (
    <ConfigProvider theme={{ ...appTheme }}>
      {messageApiContext}
      {notificationApiContext}
      <IndexContext.Provider
        value={{
          messageApi: messageApi,
          notificationApi: notificationApi,
          isLightTheme: isLightTheme,
          updateTheme: updateTheme,
        }}
      >
        <BrowserRouter basename={route_basename}>
          <Routes>
            {/* Login Route */}
            <Route path="Login" element={<LoginPage />}></Route>
            <Route path="LoginRecord" element={<LoginRecordPage />}></Route>
            {/* All content pages */}
            <Route element={<MainLayout updateTheme={updateTheme} />}>
              <Route
                path="DeliveryOrderConnection"
                element={
                  <ProtectedRoute
                    children={<DeliveryOrderConnectionPage />}
                    disabledUserTypes={[account, qcOperator]}
                  />
                }
              ></Route>
              <Route path="Analytics">
                {/* Sub route for Dashboard */}
                <Route
                  path="Dashboard"
                  element={
                    <ProtectedRoute
                      disabledUserTypes={[
                        operator,
                        supervisor,
                        account,
                        qcOperator,
                      ]}
                      children={<DashboardPage />}
                    />
                  }
                ></Route>
                {/* Sub route for Statistic*/}
                <Route
                  path="Statistics"
                  element={
                    <ProtectedRoute
                      disabledUserTypes={[
                        operator,
                        supervisor,
                        account,
                        qcOperator,
                      ]}
                      children={<StatisticsPage />}
                    />
                  }
                ></Route>
                {/* Sub route for Traceability*/}
                <Route
                  path="Traceability"
                  element={
                    <ProtectedRoute
                      disabledUserTypes={[
                        operator,
                        supervisor,
                        account,
                        qcOperator,
                      ]}
                      children={<TraceabilityPage />}
                    />
                  }
                ></Route>
              </Route>

              <Route path="DigitalMillCert">
                {/* Sub route for DO Progress*/}
                <Route
                  path="DOProgress"
                  element={
                    <ProtectedRoute
                      disabledUserTypes={[operator, account, qcOperator]}
                      children={<DOProgressPage />}
                    />
                  }
                ></Route>
                {/* Sub route for Mill Certification*/}
                <Route
                  path="DeliveryOrder"
                  element={
                    <ProtectedRoute
                      disabledUserTypes={[operator, qcOperator]}
                      children={<DeliveryOrderPage />}
                    />
                  }
                ></Route>
                {/* Sub route for Material Properties*/}
                <Route
                  path="MaterialProperties"
                  element={
                    <ProtectedRoute
                      disabledUserTypes={[operator, supervisor, account]}
                      children={<MaterialPropertiesPage />}
                    />
                  }
                ></Route>
              </Route>
              {/* Sub route for Operator */}
              <Route
                path="AIRebarCount"
                element={
                  <ProtectedRoute
                    disabledUserTypes={[account, qcOperator]}
                    children={<AIRebarCountPage />}
                  />
                }
              ></Route>
              {/* Sub route for Bundle List*/}
              <Route
                path="BundleList"
                element={
                  <ProtectedRoute
                    disabledUserTypes={[operator, account, qcOperator]}
                    children={<BundleListPage />}
                  />
                }
              ></Route>
              {/* Sub route for Rebar Verification*/}
              <Route
                path="RebarVerification"
                element={
                  <ProtectedRoute
                    disabledUserTypes={[operator, account, qcOperator]}
                    children={<RebarVerificationPage />}
                  />
                }
              ></Route>

              {/* Sub route for Configuration */}
              <Route
                path="Configuration"
                element={
                  <ProtectedRoute
                    disabledUserTypes={[
                      operator,
                      supervisor,
                      account,
                      qcOperator,
                    ]}
                    children={<ConfigurationPage />}
                  />
                }
              ></Route>
              {/* Sub route for Account*/}
              <Route path="Account">
                <Route
                  path="ChangePassword"
                  element={<ChangePassword />}
                ></Route>
              </Route>

              {/* Sub route for Test*/}
              <Route path="Test">
                <Route
                  path="CameraTest"
                  element={
                    <ProtectedRoute
                      allowedUserTypes={[0, 1]}
                      children={<CameraTest />}
                    />
                  }
                ></Route>
                <Route
                  path="QrScannerTest"
                  element={
                    <ProtectedRoute
                      allowedUserTypes={[0, 1]}
                      children={<QrScannerTest />}
                    />
                  }
                ></Route>
                <Route
                  path="DownloadTest"
                  element={
                    <ProtectedRoute
                      allowedUserTypes={[0, 1]}
                      children={<DownloadTest />}
                    />
                  }
                ></Route>
                <Route
                  path="TableDataTest"
                  element={
                    <ProtectedRoute
                      allowedUserTypes={[0, 1]}
                      children={<TableDataTest />}
                    />
                  }
                ></Route>
              </Route>
            </Route>
            {/* Default all unknown route to login page */}
            <Route path="*" element={<Navigate to="/Login" />} />
          </Routes>
        </BrowserRouter>
      </IndexContext.Provider>
    </ConfigProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
