import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useCallback } from "react";
import {
  operator,
  supervisor,
  account,
  qcOperator,
} from "../../configs/configs.js";

function useCheckUserLogin() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();

  const checkUserLogin = useCallback(() => {
    fetchWrapper({
      endpoint_url: "user_management/checkUser",
      onSuccess: (response) => {
        if (response.status) {
          if (response.user_type == operator) {
            navigate("/AIRebarCount", { replace: true });
          } else if (response.user_type == supervisor) {
            navigate("/BundleList", { replace: true });
          } else if (response.user_type == account) {
            navigate("/DigitalMillCert/DeliveryOrder", { replace: true });
          } else if (response.user_type == qcOperator) {
            navigate("/DigitalMillCert/MaterialProperties", {
              replace: true,
            });
          } else {
            navigate("/Analytics/Dashboard", { replace: true });
          }
        }
      },
    });
  }, [fetchWrapper]);

  return { checkUserLogin };
}

export default useCheckUserLogin;
