import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import { useNavigate } from "react-router-dom";
import {
  operator,
  supervisor,
  account,
  qcOperator,
} from "../../configs/configs.js";

function useLogin() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [loginProcessing, setLoginProcessing] = useState(false);

  const login = useCallback(
    (user_inputs, fingerprint) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "user_management/loginUser",
          params: {
            username: user_inputs.username,
            password: user_inputs.password,
            stage: user_inputs.stage,
            fingerprint: fingerprint,
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.login_status === true) {
              // login success
              messageApi.open({
                type: "success",
                content: "Login Success!",
              });

              setTimeout(() => {
                setLoginProcessing(false);
                if (
                  fetch_output.type == operator ||
                  fetch_output.type == supervisor
                ) {
                  navigate("/LoginRecord", { replace: true });
                } else if (fetch_output.type == account) {
                  navigate("/DigitalMillCert/DeliveryOrder", { replace: true });
                } else if (fetch_output.type == qcOperator) {
                  navigate("/DigitalMillCert/MaterialProperties", {
                    replace: true,
                  });
                } else {
                  navigate("/Analytics/Dashboard", { replace: true });
                } //replace this so back wont go back to login page
              }, 2000);
              localStorage.removeItem("delivery_order_id");
            } else {
              // incorrect credential
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });

              setLoginProcessing(false);
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to login",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content: "Failed to Responses (User Login), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { login, loginProcessing, setLoginProcessing };
}

export default useLogin;
