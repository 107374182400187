import Icon, {
  AuditOutlined,
  CalculatorOutlined,
  CameraOutlined,
  DownloadOutlined,
  FileMarkdownOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  GoldOutlined,
  LineChartOutlined,
  MergeOutlined,
  QrcodeOutlined,
  SettingOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Flex } from "antd";
import {
  operator,
  supervisor,
  account,
  qcOperator,
} from "../configs/configs.js";

const header_height = 58;

export default function useNavMenu(authenticationState) {
  const user_type = authenticationState.user_type;

  const menuLabel = (Icon, text, style = {}) => {
    return (
      <Flex align="center" justify="start" style={style}>
        <Icon style={{ marginRight: 12 }} />
        {text}
      </Flex>
    );
  };

  const userAccess = (allowUserType) => {
    return allowUserType.includes(user_type);
  };

  const NavMenu = user_type != null && [
    userAccess([0]) && {
      label: menuLabel(CameraOutlined, "Testing", {
        paddingInline: 8,
        height: header_height,
      }),
      key: "Test",
      children: [
        {
          label: menuLabel(CameraOutlined, "Camera Test", { marginRight: 8 }),
          key: "CameraTest",
        },
        {
          label: menuLabel(QrcodeOutlined, "QrScanner Test", {
            marginRight: 8,
          }),
          key: "QrScannerTest",
        },
        {
          label: menuLabel(DownloadOutlined, "Download Test", {
            marginRight: 8,
          }),
          key: "DownloadTest",
        },
        {
          label: menuLabel(TableOutlined, "Table Data Test", {
            marginRight: 8,
          }),
          key: "TableDataTest",
        },
      ],
    },
    !userAccess([account, qcOperator]) && {
      label: menuLabel(CameraOutlined, "AI Rebar Count", {
        paddingInline: 8,
        height: header_height,
      }),
      key: "AIRebarCount",
    },
    !userAccess([operator, account, qcOperator]) && {
      label: menuLabel(FileTextOutlined, "Bundle List", {
        paddingInline: 8,
        height: header_height,
      }),
      key: "BundleList",
    },
    !userAccess([operator, supervisor, account, qcOperator]) && {
      label: menuLabel(LineChartOutlined, "Analytics", {
        paddingInline: 8,
        height: header_height,
      }),
      key: "Analytics",
      children: [
        {
          label: menuLabel(FundProjectionScreenOutlined, "Dashboard", {
            marginRight: 12,
          }),
          key: "Dashboard",
        },
        {
          label: menuLabel(CalculatorOutlined, "Statistics", {
            marginRight: 12,
          }),
          key: "Statistics",
        },
        {
          label: menuLabel(MergeOutlined, "Traceability", { marginRight: 12 }),
          key: "Traceability",
        },
      ],
    },
    !userAccess([operator]) && {
      label: menuLabel(FileMarkdownOutlined, "Digital Mill Cert", {
        paddingInline: 8,
        height: header_height,
      }),
      key: "DigitalMillCert",
      children: [
        !userAccess([account, qcOperator]) && {
          label: menuLabel(GoldOutlined, "DO Progress", { marginRight: 12 }),
          key: "DOProgress",
        },
        !userAccess([qcOperator]) && {
          label: menuLabel(AuditOutlined, "Delivery Order", {
            marginRight: 12,
          }),
          key: "DeliveryOrder",
        },
        !userAccess([supervisor, account]) && {
          label: menuLabel(FileProtectOutlined, "Material Properties", {
            marginRight: 12,
          }),
          key: "MaterialProperties",
        },
      ],
    },
    !userAccess([operator, supervisor, account, qcOperator]) && {
      label: menuLabel(SettingOutlined, "Configuration", {
        paddingInline: 8,
        height: header_height,
      }),
      key: "Configuration",
    },
  ];

  return [NavMenu, header_height];
}
