import { Col, Form, Input, Typography } from "antd";
import CustomManagementForm from "../../common/CustomManagementForm";

const CUSTOMER_MANAGEMENT_FORM = [
  {
    label: "Customer Name",
    name: "customer_name",
    type: "input",
    required: true,
  },
];

export default function CustomerManagementForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [customerManagementForm] = Form.useForm();

  return (
    <CustomManagementForm
      form={customerManagementForm}
      formProperties={CUSTOMER_MANAGEMENT_FORM}
      extraFormProperties={
        <Col span={24}>
          <Typography.Text>Customer Address</Typography.Text>
          <Form.Item
            name="customer_address"
            rules={[
              {
                required: true,
                message: `Please input address`,
              },
            ]}
            children={
              <Input.TextArea
                rows={5}
                placeholder={
                  "29-JALAN TANDANG,\nSECTION 51,\n46050 PETALING JAYA\nSELANGOR DARUL EHSAN"
                }
              />
            }
          />
        </Col>
      }
      modalTitle={editData ? "Edit Customer Details" : "Add Customer Details"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      editData={editData}
      setEditData={setEditData}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}
