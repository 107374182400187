import { useCallback, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import { useNavigate } from "react-router-dom";

function useCheckDeliveryOrderConnection() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const checkDeliveryOrderConnection = useCallback(() => {
    fetchWrapper({
      endpoint_url: "delivery_order_connection/checkDeliveryOrderConnection",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          navigate("/AIRebarCount", { replace: true });
        } else {
          messageApi.open({
            type: "warning",
            content: fetch_output.error,
          });
        }
      },
      onFetchError: () => {
        messageApi.open({
          type: "error",
          content: "Network Error. Failed to check delivery order connection",
        });
      },
      onResponseError: () => {
        messageApi.open({
          type: "error",
          content:
            "Failed to Respond (Check Delivery Order Connection), Please Contact Admin",
        });
      },
    });
  }, [fetchWrapper]);

  return { checkDeliveryOrderConnection };
}

export default useCheckDeliveryOrderConnection;
