import { Form } from "antd";
import CustomModal from "../common/CustomModal";
import CustomSearchForm from "../common/CustomSearchForm";
import useGetDiameterTypeOption from "../../functions/Options/getDiameterTypeOption";
import useGetMillCertSpecificationOption from "../../functions/Options/getMillCertSpecificationOption";

const DELIVERY_ORDER_SORT = [
  {
    label: "DO",
    value: "do_number",
  },
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Number of Bundles",
    value: "number_of_bundles",
  },
  {
    label: "Create Date Time",
    value: "create_date_time",
  },
  {
    label: "Last Update Date Time",
    value: "last_update_date_time",
  },
];

export default function DeliveryOrderSearchForm({
  modalOpen,
  setModalOpen,
  tableData,
  setFilterTable,
}) {
  const [deliveryOrderSearch] = Form.useForm();

  const { diameterOption } = useGetDiameterTypeOption();
  const { millCertSpecificationOption } = useGetMillCertSpecificationOption();

  const DELIVERY_ORDER_FILTER = [
    {
      label: "DO",
      name: "do_number",
      type: "input",
    },
    {
      label: "Customer",
      name: "customer",
      type: "input",
    },
    {
      label: "Product",
      name: "product",
      type: "select",
      options: diameterOption,
    },
    {
      label: "Specification",
      name: "specification",
      type: "select",
      options: millCertSpecificationOption,
    },
    {
      label: "Description of Good",
      name: "description_of_good",
      type: "input",
    },
    {
      label: "Number of Bundles",
      name: "number_of_bundles",
      type: "input-number",
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: [
        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "In Progress",
          value: "In Progress",
        },
        {
          label: "Completed",
          value: "Completed",
        },
        {
          label: "Generated",
          value: "Generated",
        },
      ],
    },
  ];

  const handleSubmit = (value) => {
    setModalOpen(false);
    const filter = filterData(tableData, value);
    const sort = sortData(filter, value.sortTags);
    setFilterTable(sort);
  };

  const filterData = (data, filter) => {
    return data.filter((item) => {
      return Object.keys(filter).every((key) => {
        const filterValue = filter[key];
        const itemValue = item[key];

        if (key === "sortTags") return true;
        if (filterValue === undefined || filterValue === null) return true; // Skip undefined or null filters

        return String(itemValue).includes(String(filterValue));
      });
    });
  };

  const sortData = (data, sort) => {
    return data.sort((a, b) => {
      for (const { sortField, sortOrder } of sort) {
        const fieldA = a[sortField];
        const fieldB = b[sortField];

        let comparison = 0;
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
        }

        if (sortOrder === "Descending") comparison *= -1;
        if (comparison !== 0) return comparison;
      }
      return 0;
    });
  };

  return (
    <CustomModal
      title={"Advanced Search Form"}
      visible={modalOpen}
      close_icon={true}
      onCancel={() => setModalOpen(false)}
      content={
        <CustomSearchForm
          form={deliveryOrderSearch}
          onSubmit={handleSubmit}
          filterForm={DELIVERY_ORDER_FILTER}
          sorterOption={DELIVERY_ORDER_SORT}
        />
      }
    />
  );
}
