import { Flex } from "antd";

const STUCK_SUMMARY = {
  total: (
    <Flex align="center">
      <div className="circle" style={{ backgroundColor: "#D9D9D9" }} />
      Stuck {"<"} month
    </Flex>
  ),
  stuck: (
    <Flex align="center">
      <div className="circle" style={{ backgroundColor: "#1890FF" }} />
      Stuck {">"} 1 month
    </Flex>
  ),
};

export default function InventoryDonutSummary({ data }) {
  return (
    <Flex>
      <div className="flexbox-container-col" style={{ minWidth: "180px" }}>
        {STUCK_SUMMARY.total}
        {STUCK_SUMMARY.stuck}
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <span>{`:`}</span>
        <span>{`:`}</span>
      </div>
      <div
        className="flexbox-container-col"
        style={{ flexShrink: "0", flexGrow: "0" }}
      >
        <span>{data.success}</span>
        <span>{data.stuck}</span>
      </div>
    </Flex>
  );
}
