import { Button, Flex, Tag, Tooltip, Typography } from "antd";
import { useState } from "react";
import DataTable from "../common/DataTable";
import {
  QuestionCircleOutlined,
  ReloadOutlined,
  StopOutlined,
} from "@ant-design/icons";
import FunctionalButton from "../common/FunctionalButton";

export default function DOProgressTable({
  tableData,
  previewLoading,
  handlePreview,
  updateLoading,
  handleUpdate,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);

  const DO_PROGRESS_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "DO Number",
      dataIndex: "do_number",
      key: "do_number",
      width: 100,
      render: (_, record) => {
        return (
          <Button
            type="link"
            children={record.do_number}
            loading={previewLoading && loadRowKey == record.key}
            onClick={() => {
              handlePreview(record.key);
              setLoadRowKey(record.key);
            }}
          />
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 120,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 180,
      render: (address) => (
        <>
          {address.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 80,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 150,
    },
    {
      title: "Description of Good",
      dataIndex: "description_of_good",
      key: "description_of_good",
      width: 150,
    },
    {
      title: "Number of Bundles",
      dataIndex: "number_of_bundles",
      key: "number_of_bundles",
      width: 100,
    },
    {
      title: "Captured Bundles",
      dataIndex: "captured bundles",
      key: "captured_bundles",
      width: 140,
    },
    {
      title: (
        <Flex gap={"middle"}>
          <span>Status</span>
          <Tooltip
            title={
              <Flex vertical="vertical">
                <span>
                  <strong>Pending:</strong> The delivery order has just been
                  created.
                </span>
                <span>
                  <strong>In Progress:</strong> Some bundles are currently in
                  the delivery order.
                </span>
                <span>
                  <strong>Completed:</strong> All bundles in the delivery order
                  have been verified.
                </span>
              </Flex>
            }
            children={<QuestionCircleOutlined />}
          />
        </Flex>
      ),
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (_, record) => {
        return record.status == "Pending" ? (
          <Tag color="yellow" children={record.status} />
        ) : record.status == "In Progress" ? (
          <Tag color="processing" children={record.status} />
        ) : record.status == "Error" ? (
          <Tag color="error" children={record.status} />
        ) : (
          <Tag color="success" children={record.status} />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "create_by",
      key: "create_by",
      width: 100,
    },
    {
      title: "Create Date Time",
      dataIndex: "create_date_time",
      key: "create_date_time",
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_, record) => {
        return (
          <Flex align="center" justify="center" gap="middle">
            <FunctionalButton
              buttonClickFunction={() => {
                handleUpdate(record.key);
                setLoadRowKey(record.key);
              }}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content: "Sure to stop this delivery order?",
              }}
              danger={true}
              width="40px"
              icon={<StopOutlined />}
              trigger_loading={
                updateLoading && loadRowKey == record.key && record.stop_action
              }
              disabled={!record.stop_action}
            />
            <FunctionalButton
              buttonClickFunction={() => {
                handleUpdate(record.key);
                setLoadRowKey(record.key);
              }}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content: "Sure to resume this delivery order ?",
              }}
              width="40px"
              icon={<ReloadOutlined />}
              trigger_loading={
                updateLoading &&
                loadRowKey == record.key &&
                record.reload_action
              }
              disabled={!record.reload_action}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        table_column={DO_PROGRESS_TABLE_COLUMN}
        table_data={tableData}
        tableRowSelection={false}
        pageSize={7}
      />
    </>
  );
}
