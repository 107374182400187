import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import { useNavigate } from "react-router-dom";

function useAddDeliveryOrderConnection() {
  const navigate = useNavigate();

  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [addLoading, setAddLoading] = useState(false);

  const addDeliveryOrderConnection = useCallback(
    (delivery_order_id, data) => {
      return new Promise((resolve, reject) => {
        setAddLoading(true);
        fetchWrapper({
          endpoint_url: "delivery_order_connection/addDeliveryOrderConnection",
          params: {
            delivery_order_id: delivery_order_id,
            data: data,
          },
          setLoading: setAddLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
              navigate("/AIRebarCount", { replace: true });
              localStorage.setItem(
                "delivery_order_id",
                fetch_output.delivery_order_id
              );
            } else {
              messageApi.open({
                type: "warning",
                content: fetch_output.error,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
          onFetchError: () => {
            messageApi.open({
              type: "error",
              content: "Network Error. Failed to add delivery order connection",
            });
          },
          onResponseError: () => {
            messageApi.open({
              type: "error",
              content:
                "Failed to Respond (Add Delivery Order Connection), Please Contact Admin",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { addDeliveryOrderConnection, addLoading };
}

export default useAddDeliveryOrderConnection;
