import PropTypes from 'prop-types';
import React from 'react';

// General validation function
export const validateProp = (type, pattern) => {
    return (props, propName, componentName) => {
        const value = props[propName];

        switch (type) {
            case 'tabs': {
                if (value === undefined || value === null) {
                    return new Error(`${propName} in ${componentName} should not be null or undefined.`);
                }
                if (!Array.isArray(value)) {
                    return new Error(`${propName} in ${componentName} must be an array.`);
                }
                for (const tab of value) {
                    if (typeof tab.title !== 'string') {
                        return new Error(`Each item in ${propName} should have a 'title' of type 'string'.`);
                    }
                    if (!React.isValidElement(tab.content) && typeof tab.content !== 'string') {
                        return new Error(`Each item in ${propName} should have 'content' that is a valid React element or a string.`);
                    }
                }
                return null;
            }
            case 'style': {
                if (value === undefined || value === null) {
                    return null; // Optionally handle undefined or null as valid
                }
                if (typeof value !== 'object' || value === null) {
                    return new Error(`${propName} in ${componentName} should be an object.`);
                }
                if (Object.keys(value).length === 0) {
                    return new Error(`${propName} in ${componentName} should not be an empty object.`);
                }
                return null;
            }
            case 'string': {
                if (value === undefined || value === null) {
                    return null; // Optionally handle undefined or null as valid
                }
                if (typeof value !== 'string') {
                    return new Error(`${propName} in ${componentName} must be a string.`);
                }
                if (pattern && !pattern.test(value)) {
                    return new Error(`${propName} in ${componentName} must match the pattern ${pattern.toString()}.`);
                }
                return null;
            }
            case 'width': {
                if (value === undefined || value === null) {
                    return null; // Optionally handle undefined or null as valid
                }
                if (typeof value === 'number') {
                    return null; // Valid if it's a number
                }
                if (typeof value === 'string') {
                    const pattern = /^(100%|[0-9]+px)$/;
                    if (!pattern.test(value)) {
                        return new Error(`${propName} in ${componentName} must be either '100%' or a value with 'px'.`);
                    }
                    return null; // Valid if it matches the pattern
                }
                return new Error(`${propName} in ${componentName} must be a number or a string matching '100%' or '[0-9]+px'.`);
            }
            case 'boolean': {
                if (value === undefined || value === null) {
                    return null; // Optionally handle undefined or null as valid
                }
                if (typeof value !== 'boolean') {
                    return new Error(`${propName} in ${componentName} must be a boolean.`);
                }
                return null;
            }
            case 'node': {
                if (value === undefined || value === null) {
                    return null; // Optionally handle undefined or null as valid
                }
                if (!React.isValidElement(value) && typeof value !== 'string' && typeof value !== 'number') {
                    return new Error(`${propName} in ${componentName} must be a valid React node.`);
                }
                return null;
            }
            case 'shape': {
                if (value === undefined || value === null) {
                    return new Error(`${propName} in ${componentName} should not be null or undefined.`);
                }
                if (typeof value !== 'object' || value === null) {
                    return new Error(`${propName} in ${componentName} should be an object.`);
                }
                const { title, icon, children } = value;
                if (typeof title !== 'string') {
                    return new Error(`${propName}.title in ${componentName} must be a string.`);
                }
                if (!React.isValidElement(icon) && typeof icon !== 'string' && typeof icon !== 'number') {
                    return new Error(`${propName}.icon in ${componentName} must be a valid React element, string, or number.`);
                }
                if (!React.isValidElement(children) && typeof children !== 'string') {
                    return new Error(`${propName}.children in ${componentName} must be a valid React element or a string.`);
                }
                return null;
            }
            default:
                return new Error(`Invalid validation type: ${type}.`);
        }
    };
};
