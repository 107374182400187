import Camera from "../../components/ai_rebar_count/camera/CameraFunc";
import { useEffect, useState } from "react";
import { Col, Flex, Input, Row } from "antd";
import useSnapCameraTestImage from "../../functions/Test/snapCameraTestImage";
import useSnapRebarImageTest from "../../functions/Test/snapRebarImageTest";
import useCheckRebarTestStatus from "../../functions/Test/checkRebarTestStatus";
import useCheckCameraTestStatus from "../../functions/Test/checkCameraTestStatus";

export default function CameraTest() {
  const [imageUploading, setImageUploading] = useState({
    state: false,
    progress: 0,
    res: null,
  });
  // const [resultType, setResultType] = useState(null);

  const tagId = "123456,BR-12-B-240904-1130";

  // Function to load progress after confirm the image
  function loadProgress() {
    const interval = setInterval(() => {
      setImageUploading((prevState) => {
        if (prevState.progress >= 90) {
          clearInterval(interval);
          return prevState;
        }
        return { ...prevState, progress: prevState.progress + 1, state: true };
      });
    }, 1000);
  }

  const loadingReset = () => {
    setImageUploading({ state: false, progress: 0, res: null });
  };

  // // Api Funciton to add Camera Test with Captured Rebar Image
  // const { snapCameraTestImage } = useSnapCameraTestImage();

  // // Api Function to check the Camera Test Status
  // const { checkCameraTestStatus, rebarCount, setRebarCount, aiImageSrc } =
  //   useCheckCameraTestStatus();

  const { snapRebarImageTest } = useSnapRebarImageTest();

  const {
    checkRebarTestStatus,
    rebarCount,
    setRebarCount,
    resultType,
    aiImageSrc,
  } = useCheckRebarTestStatus();

  // For Submit the Captured Rebar Image
  const handleCameraTestImageConfirm = (value) => {
    loadProgress();
    // snapCameraTestImage(
    //   value,
    //   checkCameraTestStatus,
    //   setImageUploading,
    //   setResultType
    // );
    snapRebarImageTest(value, checkRebarTestStatus, setImageUploading);
  };

  // For Confirm the Camera Result Modal
  const onResultConfirm = () => {
    setRebarCount(null);
  };

  return (
    <Flex justify="center">
      <div className="responsive-row">
        <Flex vertical="vertical" gap="small">
          <Row>
            <Col className="responsive-col">
              <Input addonBefore="Tag ID:" value={tagId} readOnly />
            </Col>
          </Row>
          <Camera
            content={"Please take a picture with Circle"}
            shape={"circle"}
            cameraSide={"back"}
            setFinalImage={handleCameraTestImageConfirm}
            isLoading={imageUploading}
            resultType={resultType}
            rebarCount={rebarCount}
            aiImageSrc={aiImageSrc}
            onResultConfirm={onResultConfirm}
            loadingReset={loadingReset}
          />
        </Flex>
      </div>
    </Flex>
  );
}
