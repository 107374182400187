import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import CustomModal from "../common/CustomModal";
import { useEffect } from "react";
import CustomFormItems from "../common/CustomFormItems";
import useGetBarMarkOption from "../../functions/Options/getBarMarkOption";
import useGetMillCertSpecificationOption from "../../functions/Options/getMillCertSpecificationOption";
import useGetDiameterTypeOption from "../../functions/Options/getDiameterTypeOption";
import useGetCustomerOption from "../../functions/Options/getCustomerOption";

const { Text } = Typography;

export default function DeliveryOrderForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [millCertForm] = Form.useForm();
  const { barMarkOption } = useGetBarMarkOption();
  const { millCertSpecificationOption } = useGetMillCertSpecificationOption();
  const { diameterOption } = useGetDiameterTypeOption();
  const { customerOption } = useGetCustomerOption();

  const handleFieldChange = () => {
    const customerNameValue = millCertForm.getFieldValue("customer");

    if (customerNameValue != undefined) {
      const customer = customerOption.find(
        (each) => each.value === customerNameValue
      );
      millCertForm.setFieldValue("address", customer.address);
    }
  };

  // For Reset the Form Field
  const onResetForm = () => {
    millCertForm.resetFields();
  };

  const onFinish = (value) => {
    if (editData) {
      onSubmit(value, editData["key"]);
    } else onSubmit(value, null);
  };

  useEffect(() => {
    if (modalOpen == false) {
      millCertForm.resetFields();
      setEditData(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (editData) millCertForm.setFieldsValue(editData);
  }, [editData]);

  const MILL_CERTIFICATION_FORM = [
    {
      label: "Customer",
      name: "customer",
      type: "select",
      required: true,
      options: customerOption,
    },
    {
      label: "Product",
      name: "product",
      type: "select",
      quarter: true,
      required: true,
      options: diameterOption,
    },
    {
      label: "Specification",
      name: "specification",
      type: "select",
      quarter: true,
      required: true,
      options: millCertSpecificationOption,
    },
    {
      label: "Delivery Order ID",
      name: "delivery_order_id",
      type: "input",
      quarter: true,
      required: true,
    },
    {
      label: "Customer Order ID",
      name: "customer_order_id",
      type: "input",
      quarter: true,
      required: true,
    },
    {
      label: "Number of Rebar Bundles",
      name: "number_of_rebar_bundles",
      type: "input-number",
      required: true,
      quarter: true,
    },
    {
      label: "Bar Marker",
      name: "bar_marker",
      type: "select",
      required: true,
      options: barMarkOption,
    },
    {
      label: "Lorry Plate",
      name: "lorry_plate",
      type: "input",
      quarter: true,
    },
  ];

  return (
    <CustomModal
      title={editData ? "Edit Delivery Order" : "Add Delivery Order"}
      close_icon={true}
      visible={modalOpen}
      width={1000}
      onCancel={() => setModalOpen(false)}
      content={
        <>
          <Form
            form={millCertForm}
            onFinish={onFinish}
            onFieldsChange={handleFieldChange}
          >
            <Divider
              orientation="left"
              orientationMargin="0"
              children={<strong>D.O.</strong>}
              style={{ borderColor: "#C0C0C0" }}
            />
            <Row gutter={[16, 16]}>
              <CustomFormItems form_properties={MILL_CERTIFICATION_FORM} />
              <Col span={24}>
                <Text>Address</Text>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: `Please input address`,
                    },
                  ]}
                  children={<Input.TextArea rows={5} />}
                />
              </Col>
            </Row>
            <Flex gap="middle" justify="flex-end">
              <Form.Item>
                <Button onClick={onResetForm} type="link" disabled={loading}>
                  Clear All
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {editData ? "Update Job" : "Create Job"}
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </>
      }
    />
  );
}
